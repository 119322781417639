import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  AddCircleOutlineOutlined,
  RemoveCircleOutlineOutlined,
} from "@mui/icons-material";
import { useSelector } from "react-redux";

export default function AddVehicleModel() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [categoryList, setCategoryList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modelNo, setModelNo] = useState("")
  const editData = useSelector((state) => state.login.editVehicalModel)
  const [data, setData] = useState([
    {
        vehicalCategoryId: "",
        vehicalModel: "",
    },
  ]);

  // console.log(id)
  const notify = (val, type = 200) => {
    if (type === 200) {
      toast.success(val);
    } else {
      toast.error(val);
    }
  };

  useEffect(() => {
    fetchCatList();
  }, []);

  const fetchCatList = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_URL}api/vehicalType/getAllVehicalList`
      );
      setCategoryList(res?.data?.res);
    } catch (error) {
      console.log(error);
    }
  };

  const addtax = async (e) => {
    e.preventDefault();
    let formselect = {
      vehicalBrand: modelNo,
      modelsValues: data,
    };
    let formselect1 = {
      vehicalBrand: modelNo,
      modelsValues: data,
      id: id
    };
    console.log(formselect)
    setLoading(true);
    try {
      const res = !id ? await axios.post(`${process.env.REACT_APP_URL}api/admin/add-vehicalCategory`,formselect) :
       await axios.put(`${process.env.REACT_APP_URL}api/admin/update-vehical-category`,formselect1);
      if (res?.status === 200) {
        setLoading(false);
        notify(res.msg, res.status);
        navigate("/admin/VehicleCategories");
      }
    } catch (error) {
      notify(error.response.data.msg, error.response.data.status);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      fetchData();
    }
    // eslint-disable-next-line
  }, [id]);

  const fetchData = async () => {
    console.log(editData)
    setModelNo(editData.vehicalBrand)
    setData(editData.modelsValues)
  };

  const handleChange = (e, dataIndex) => {
    const { name, value } = e.target;
    const updatedRows = [...data];
    updatedRows[dataIndex] = { ...updatedRows[dataIndex], [name]: value };
    setData(updatedRows);
  };
  const addNewRow = () => {
    setData([...data, { vehicalModel: "", vehicalCategoryId: ""}]);
  };

  const removeRow = (dataIndex) => {
    const updatedRows = data.filter((_, index) => index !== dataIndex);
    setData(updatedRows);
  };


  return (
    <div className="container-fluid container-main">
      <Box sx={{ p: 3 }}>
        <div className="d-flex justify-content-between align-items-center mb-4">
          <div className="top-heading">Add Vehical Brand</div>
          <div>
            <Button
              variant="outlined"
              className="px-3"
              size="small"
              onClick={() => navigate("/admin/VehicleCategories")}
            >
              Back
            </Button>
          </div>
        </div>
        <Paper className="p-4">
          <form onSubmit={addtax}>
            <div className="">
              <div className="d-flex w-100 gap-4 mb-4">
                <FormControl fullWidth size="small">
                    <TextField
                        fullWidth
                        size="small"
                        variant="outlined"
                        type="text"
                        value={modelNo}
                        required
                        label="Vehical Brand"
                        onChange={(e) => setModelNo(e.target.value)}
                    />
                </FormControl>
              </div>
              <Divider className="my-4" sx={{ borderColor: "#000" }} />
              <div className="w-100">
                {data.map((item, index) => (
                  <div className="d-flex gap-4 mb-4">
                    <div className="d-flex gap-4 flex-grow-1">
                        <TextField
                            fullWidth
                            size="small"
                            variant="outlined"
                            type="text"
                            name={"vehicalModel"}
                            value={item.vehicalModel}
                            required
                            label="Vehical Model"
                            onChange={(e) => handleChange(e, index)}
                        />
                        <FormControl fullWidth size="small">
                            <InputLabel sx={{ fontSize: "16px" }}>
                                Vehicle Category
                            </InputLabel>
                            <Select
                                className=""
                                value={item.vehicalCategoryId}
                                label="Vehicle Category"
                                name="vehicalCategoryId"
                                onChange={(e) => handleChange(e, index)}
                                required
                                style={{ borderRadius: "5.86877px" }}
                            >
                                {categoryList?.length > 0 &&
                                categoryList?.map((currentValue) => {
                                    return (
                                    <MenuItem value={currentValue._id}>
                                        {currentValue.vehicalName}
                                    </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </div>
                    <div className="d-flex gap-4 align-content-center">
                      <IconButton onClick={() => addNewRow(index)}>
                        <AddCircleOutlineOutlined />
                      </IconButton>
                      {data.length > 1 && (
                        <IconButton
                          className=""
                          onClick={() => removeRow(index)}
                        >
                          <RemoveCircleOutlineOutlined />
                        </IconButton>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="text-center my-5">
              <LoadingButton
                variant="contained"
                type="submit"
                className="px-4"
                loading={loading}
              >
                Save
              </LoadingButton>
            </div>
          </form>
        </Paper>
      </Box>
    </div>
  );
}
