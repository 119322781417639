import React, { useState } from "react";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import { toast } from "react-toastify";
import {
  Box,
  Button,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Toolbar,
  Typography,
  FormControl,
  Link,
  Select,
} from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import MenuIcon from "@mui/icons-material/Menu";
import { styled, useTheme } from "@mui/material/styles";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import partnerLogo from "../../Assets/AdminImg/partner.png";
import dashlogo from "../../Assets/AdminImg/Dasboard.png";
import SpeedIcon from "@mui/icons-material/Speed";
import vehiclelogo from "../../Assets/AdminImg/vehicle.png";
import Logo from "../../Assets/Images/logo.png";
import SettingsIcon from "@mui/icons-material/Settings";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import PeopleIcon from "@mui/icons-material/People";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import EuroIcon from '@mui/icons-material/Euro';
import EditLocationIcon from '@mui/icons-material/EditLocation';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import CommuteIcon from '@mui/icons-material/Commute';
import FunctionsIcon from '@mui/icons-material/Functions';
import { paths } from "../../config/constants";
import { useSelector } from "react-redux";
import { authToken } from "../../redux/reducers/login";

const drawerWidth = 240;
const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const LinkWrapper = styled(Link)(() => ({
  color: "#CFC4B7",
  fontFamily: "DM Sans",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "16px",
  lineHeight: " 21px",
  textDecoration: "none",
}));

// const SelectWrapper = styled(Select)(() => ({
//   color: "white",
//   background: "black",
//   "&.MuiPopover-paper": {
//     color: "white",
//     backgroundColor: "black",
//   },
// }));

export default function AdminLayout() {
  const theme = useTheme();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [openDrawer, setOpenDrawer] = React.useState(true);

  const [partner, setPartner] = React.useState("part");
  const loginRole = useSelector(authToken);
  const loginRoles = loginRole.payload.login.user.data.role

  const handleDrawerOpen = () => {
    setOpenDrawer(true);
  };

  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };

  const [vehicle, setVehicle] = useState("vehicle1");
  const [zone, setZone] = useState("zone");
  const [price, setPrice] = useState("price");
  const [extraOptions, setExtraOptions] = useState(" ");

  const handleChangeVehicle = (event) => {
    setVehicle(event.target.value);
  };

  const handleChangePartner = (event) => {
    setPartner(event.target.value);
  };

  const handleLogout = () => {
    localStorage.clear();
    toast.success("Logout Successfully");
    navigate(paths.home);
  };

  return (
    <>
      <div style={{ backgroundColor: "#F5F4F0" }}>
        <div className="d-flex">
          <CssBaseline />
          <div>
           
            <Drawer
              variant="permanent"
              open={openDrawer}
              PaperProps={{
                sx: {
                  backgroundColor: "#1A1F23",
                  color: "white",
                  // overflow:"hidden",
                },
              }}
            >
              <DrawerHeader>
                <Box display="flex" mt={1}>
                  <Box p={1}>
                    <img src={Logo} width="100%" height="auto" />
                  </Box>
                  
                </Box>
              </DrawerHeader>
              <Divider />
              <List>
                <ListItem disablePadding sx={{ display: "block" }}>
                  <NavLink to={paths.admin.dashboard} className="sidelink">
                    <ListItemButton
                      spacing={4}
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                        color: "white",
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : "auto",
                          justifyContent: "center",
                          color: "white",
                        }}
                      >
                        <SpeedIcon />
                      </ListItemIcon>
                      <ListItemText>
                        <Typography sx={{ marginInlineStart: "19px" }}>
                          {" "}
                          Dashboard
                        </Typography>
                      </ListItemText>
                    </ListItemButton>
                  </NavLink>
                </ListItem>

                <ListItem disablePadding sx={{ display: "block" }}>
                  <NavLink to={paths.admin.userManager} className="sidelink">
                    <ListItemButton
                      spacing={4}
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                        color: "white",
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : "auto",
                          justifyContent: "center",
                          color: "white",
                        }}
                      >
                        <PeopleIcon />
                      </ListItemIcon>
                      <ListItemText>
                        <Typography sx={{ marginInlineStart: "19px" }}>
                          User Manager
                        </Typography>
                      </ListItemText>
                    </ListItemButton>
                  </NavLink>
                </ListItem>

                <ListItem disablePadding sx={{ display: "block" }}>
                  <NavLink to={paths.admin.partnerList} className="sidelink">
                    <ListItemButton
                      spacing={2}
                      sx={{
                        minHeight: 28,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                        color: "white",
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : "auto",
                          justifyContent: "center",
                          color: "white",
                        }}
                      >
                        <SettingsIcon />
                      </ListItemIcon>
                      <ListItemText>
                        <Typography sx={{ marginInlineStart: "19px" }}>
                          Partner List
                        </Typography>
                      </ListItemText>
                    </ListItemButton>
                  </NavLink>
                </ListItem>

                <ListItem disablePadding sx={{ display: "block" }}>
                  <NavLink to={paths.admin.adminbooking} className="sidelink">
                    <ListItemButton
                      spacing={4}
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                        color: "white",
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : "auto",
                          justifyContent: "center",
                          color: "white",
                        }}
                      >
                        <PhoneInTalkIcon />
                      </ListItemIcon>
                      <ListItemText>
                        <Typography sx={{ marginInlineStart: "19px" }}>
                          Phone Booking
                        </Typography>
                      </ListItemText>
                    </ListItemButton>
                  </NavLink>
                </ListItem>
              {/* </List>


              <List> */}
              { loginRoles === "0" && <span>

              
                <ListItem disablePadding sx={{ display: "block" }}>
                  <ListItemButton
                    spacing={4}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                      color: "white",
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                        color: "white",
                      }}
                    >
                      <img src={vehiclelogo} width="20px" />
                    </ListItemIcon>
                    <ListItemText>
                      <Typography sx={{ marginInlineStart: "19px" }}>
                        <FormControl variant="standard" fullWidth>
                          <Select
                            size="small"
                            sx={{
                              color: "white",
                              ".MuiSvgIcon-root": {
                                color: "#fff",
                              },
                            }}
                            value={vehicle}
                            onChange={handleChangeVehicle}
                          >
                            <MenuItem value="vehicle1" disabled>
                            {" "} Vehicle
                            </MenuItem>
                            <MenuItem value="vehicle list" onClick={() => navigate(paths.admin.allVehicle)}>
                                Vehicle List
                            </MenuItem>
                            <MenuItem value="vehicle add" onClick={() => navigate(paths.admin.addnewVehicle)}>
                                Add Vehicle
                            </MenuItem>
                            <MenuItem value="vehicle add" onClick={() => navigate(paths.admin.vehicleCategories)}>
                                Vehical Categories
                            </MenuItem>
                            <MenuItem value="vehicle add" onClick={() => navigate(paths.admin.vehicleColor)}>
                                Vehical Color
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Typography>
                    </ListItemText>
                  </ListItemButton>
                </ListItem>

                
                <ListItem disablePadding sx={{ display: "block" }}>
                  <NavLink to={paths.admin.coupon} className="sidelink">
                    <ListItemButton
                      spacing={4}
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                        color: "white",
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : "auto",
                          justifyContent: "center",
                          color: "white",
                        }}
                      >
                        <LocalOfferIcon />
                      </ListItemIcon>
                      <ListItemText>
                        <Typography sx={{ marginInlineStart: "19px" }}>
                          Coupon
                        </Typography>
                      </ListItemText>
                    </ListItemButton>
                  </NavLink>
                </ListItem>

                <ListItem disablePadding sx={{ display: "block" }}>
                  <ListItemButton
                    spacing={4}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                      color: "white",
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                        color: "white",
                      }}
                    >
                      <EuroIcon />
                    </ListItemIcon>
                    <ListItemText>
                      <Typography sx={{ marginInlineStart: "19px" }}>
                        <FormControl variant="standard" fullWidth>
                          <Select
                            size="small"
                            sx={{
                              color: "white",
                              ".MuiSvgIcon-root": {
                                color: "#fff",
                              },
                            }}
                            value={price}
                            onChange={(e) => setPrice(e.target.value)}
                          >
                            <MenuItem value="price" disabled>
                              Price / KM
                            </MenuItem>
                            <MenuItem value="pricekm" onClick={() => navigate(paths.admin.newPriceRule)}>
                                Price / KM{" "}
                            </MenuItem>
                            <MenuItem value="commonprice" onClick={() => navigate(paths.admin.commonPrice)}>
                                Common Price Rule
                            </MenuItem>
                            <MenuItem value="airportprice" onClick={() => navigate(paths.admin.airportPrice)}>
                                Airport Price Rule
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Typography>
                    </ListItemText>
                  </ListItemButton>
                </ListItem>

                <ListItem disablePadding sx={{ display: "block" }}>
                  <NavLink
                    to={paths.admin.vehicleCategorySec}
                    className="sidelink"
                  >
                    <ListItemButton
                      spacing={4}
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                        color: "white",
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : "auto",
                          justifyContent: "center",
                          color: "white",
                        }}
                      >
                        <CommuteIcon />
                      </ListItemIcon>
                      <ListItemText>
                        <Typography sx={{ marginInlineStart: "19px" }}>
                          Vehicle Priority
                        </Typography>
                      </ListItemText>
                    </ListItemButton>
                  </NavLink>
                </ListItem>

                <ListItem disablePadding sx={{ display: "block" }}>
                  <ListItemButton
                    spacing={4}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                      color: "white",
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                        color: "white",
                      }}
                    >
                      <EditLocationIcon />
                    </ListItemIcon>
                    <ListItemText>
                      <Typography sx={{ marginInlineStart: "19px" }}>
                        <FormControl variant="standard" fullWidth>
                          <Select
                            size="small"
                            sx={{
                              color: "white",
                              ".MuiSvgIcon-root": {
                                color: "#fff",
                              },
                            }}
                            value={zone}
                            onChange={(e) => setZone(e.target.value)}
                          >
                            {/* <MenuItem value="zone" disabled>
                              Zone{" "}
                            </MenuItem> */}
                            <MenuItem value="zone" onClick={() => navigate(paths.admin.zone)}>
                              Zone
                            </MenuItem>
                            <MenuItem value="pricerule" onClick={() => navigate(paths.admin.priceRule)}>
                            Price Rule
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Typography>
                    </ListItemText>
                  </ListItemButton>
                </ListItem>

                
                <ListItem disablePadding sx={{ display: "block" }}>
                  <NavLink to={paths.admin.taxValue} className="sidelink">
                    <ListItemButton
                      spacing={4}
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                        color: "white",
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : "auto",
                          justifyContent: "center",
                          color: "white",
                        }}
                      >
                        <FunctionsIcon />
                      </ListItemIcon>
                      <ListItemText>
                        <Typography sx={{ marginInlineStart: "19px" }}>
                          Tax Value
                        </Typography>
                      </ListItemText>
                    </ListItemButton>
                  </NavLink>
                </ListItem>
                <ListItem disablePadding sx={{ display: "block" }}>
                  <NavLink to={paths.admin.paymentdetail} className="sidelink">
                    <ListItemButton
                      spacing={4}
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                        color: "white",
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : "auto",
                          justifyContent: "center",
                          color: "white",
                        }}
                      >
                        <FunctionsIcon />
                      </ListItemIcon>
                      <ListItemText>
                        <Typography sx={{ marginInlineStart: "19px" }}>
                          Payment Detail
                        </Typography>
                      </ListItemText>
                    </ListItemButton>
                  </NavLink>
                </ListItem>

                <ListItem disablePadding sx={{ display: "block" }}>
                  <ListItemButton
                    spacing={4}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                      color: "white",
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                        color: "white",
                      }}
                    >
                      <SettingsIcon />
                    </ListItemIcon>
                    <ListItemText>
                      <Typography sx={{ marginInlineStart: "19px" }}>
                        <FormControl variant="standard" fullWidth>
                          <Select
                            size="small"
                            sx={{
                              color: "white",
                              ".MuiSvgIcon-root": {
                                color: "#fff",
                              },
                            }}
                            value={extraOptions}
                            onChange={(e) => setExtraOptions(e.target.value)}
                          >
                            <MenuItem value=" " disabled>
                              Extra Options{" "}
                            </MenuItem>
                            <MenuItem value="package" onClick={() => navigate(paths.admin.package)}>
                              Packages
                            </MenuItem>
                            <MenuItem value="childseat" onClick={() => navigate(paths.admin.childSeat)}>
                              Child Seat
                            </MenuItem>
                            <MenuItem value="extraoption" onClick={() => navigate(paths.admin.extraOptions)}>
                              Extra Options
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Typography>
                    </ListItemText>
                  </ListItemButton>
                </ListItem>

              </span>}
               
              </List>

              <Button
                className="ms-4"
                sx={{
                  backgroundColor: "#FF4848",
                  borderRadius: "10px",
                  maxWidth:"190px",
                  ":hover": {
                    backgroundColor: "#FF2626",
                  },
                }}
                onClick={handleLogout}
                style={{ color: "white", fontWeight: "700" }}
              >
                <PowerSettingsNewIcon className="me-1" sx={{ height: "18px", width: "18px" }} />{" "}
                Log Out
              </Button>
            </Drawer>
          </div>
          <div style={{ width: "100%", backgroundColor: "#fff" }}>
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
}
