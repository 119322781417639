import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  FormControl,
  Paper,
  TextField,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

export default function AddVehicleColor() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [color, setColor] = useState("")
  const editData = useSelector((state) => state.login.editVehicalColor)

  // console.log(id)
  const notify = (val, type = 200) => {
    if (type === 200) {
      toast.success(val);
    } else {
      toast.error(val);
    }
  };

  const addtax = async (e) => {
    e.preventDefault();
    let formselect = {
      color: color,
    };
    let formselect1 = {
      color: color,
      id: id
    };
    setLoading(true);
    try {
      const res = !id ? await axios.post(`${process.env.REACT_APP_URL}api/admin/create-colorVehical`,formselect) :
       await axios.put(`${process.env.REACT_APP_URL}api/admin/update-color-vehical`,formselect1);
      if (res?.status === 200) {
        setLoading(false);
        notify(res.msg, res.status);
        navigate("/admin/vehicleColour");
      }
    } catch (error) {
      notify(error.response.data.msg, error.response.data.status);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      fetchData();
    }
    // eslint-disable-next-line
  }, [id]);

  const fetchData = async () => {
    console.log(editData)
    setColor(editData.color)
  };


  return (
    <div className="container-fluid container-main">
      <Box sx={{ p: 3 }}>
        <div className="d-flex justify-content-between align-items-center mb-4">
          <div className="top-heading">Add Vehical Color</div>
          <div>
            <Button
              variant="outlined"
              className="px-3"
              size="small"
              onClick={() => navigate("/admin/vehicleColour")}
            >
              Back
            </Button>
          </div>
        </div>
        <Paper className="p-4">
          <form onSubmit={addtax}>
            <div className="">
              <div className="d-flex w-100 gap-4 mb-4">
                <FormControl fullWidth size="small">
                    <TextField
                        fullWidth
                        size="small"
                        variant="outlined"
                        type="text"
                        value={color}
                        required
                        label="Vehical Color"
                        onChange={(e) => setColor(e.target.value)}
                    />
                </FormControl>
              </div>
            </div>
            <div className="text-center my-5">
              <LoadingButton
                variant="contained"
                type="submit"
                className="px-4"
                loading={loading}
              >
                Save
              </LoadingButton>
            </div>
          </form>
        </Paper>
      </Box>
    </div>
  );
}
