import styled from "@emotion/styled";
import {
  Box,
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getEditVehicalColor } from "../../redux/reducers/login";

const PaperWrapper = styled(Paper)(() => ({
  ".partnertext": {
    fontFamily: "DM Sans",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "32px",
    lineHeight: "42px",
    color: "#1F3648;",
    width: "100%",
  },
  ".drowpdown": {
    borderRadius: "5.86877px",
  },
}));
const TableCellWraper = styled(TableCell)(() => ({
  fontstyle: "normal",
  fontWeight: 500,
  fontSize: "15.65px",
  lineHeight: " 20px",
  color: "#000000",
}));
const BodyTableCellWraper = styled(TableCell)(() => ({
  fontstyle: "normal",
  fontWeight: 500,
  fontSize: "15.65px",
  lineHeight: " 20px",
  color: "#000000",
}));

export default function VehicleColor() {
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    fetchList();
  }, []);

  const fetchList = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_URL}api/admin/get-all-color-vehical-list`
      );
      setData(res?.data?.result || []);
    } catch (error) {
      console.log(error);
    }
  };

  const handleEdit = (item) => {
    dispatch(getEditVehicalColor(item))
    navigate(`/admin/addVehicleColour/${item._id}`);
  };
  const handleAdd = () => {
    dispatch(getEditVehicalColor(null))
    navigate(`/admin/addVehicleColour`);
  };

  const handleDelete = async (id) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_URL}api/admin/delete-color-vehical`,
        {
          id: id,
        }
      );
      if (res?.status === 200) {
        fetchList();
      }
    } catch (error) {}
  };


  return (
    <div className="container-fluid container-main">
      <Box mt={0}>
        <Box component="main" sx={{ p: 3 }}>
          <Grid container>
            <Grid item md={12}>
              <div className="d-flex justify-content-between align-items-center mb-4">
                <div className="top-heading">Vehical Colors</div>
                <div className="d-flex gap-3 text-end">
                  <Button
                    variant="outlined"
                    className="px-4"
                    onClick={() => handleAdd()}
                  >
                    Add
                  </Button>
                </div>
              </div>
              <PaperWrapper className="p-3 mb-4">
                <Box mt={3}>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCellWraper>No</TableCellWraper>
                          <TableCellWraper>Color</TableCellWraper>
                          <TableCellWraper>Action</TableCellWraper>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data.map((item, i) => (
                          <TableRow>
                            <TableCell>{i + 1}</TableCell>
                            <BodyTableCellWraper>
                              {item.color}
                            </BodyTableCellWraper>
                            <BodyTableCellWraper>
                              <Button
                                variant="outlined"
                                onClick={() => handleEdit(item)}
                                size="small"
                              >
                                Edit
                              </Button>
                              <Button
                                variant="outlined"
                                onClick={() => handleDelete(item._id)}
                                className="ms-2"
                                color="error"
                                size="small"
                              >
                                Delete
                              </Button>
                            </BodyTableCellWraper>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </PaperWrapper>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </div>
  );
}
