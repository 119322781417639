import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  token: null,
  user: {},
  // admin: null,
  login: false,
  vehicledetail:{},
  taxDetail:{},
  zoneDetail:{},
  priceDetail:{},
  partnerDetail:{},
  editPackage:null,
  editChildSeat:null,
  editExtraOptions:null,
  assignDriverId:null,
  editVehicalModel:null,
  editVehicalColor:null,
  // reg: false,
  // show: false,
};
const login = createSlice({
  name: "auth",
  initialState,
  reducers: {
    authToken: (state, action) => {
      state.token = action.payload.token;
      state.user = action.payload
    },
    auth: (state, action) => {
      state.user = action.payload;
      state.login = true;
    },
    logoutUser: (state, action) => {
      state.user = {};
      state.login = false;
    },
    getvehicledetail: (state,action) => {
      state.vehicledetail=action.payload
    },
    gettaxdetail: (state,action) => {
      state.taxDetail=action.payload
    },
    getzonedetail: (state,action) => {
      state.zoneDetail=action.payload
    },
    getpricedetail: (state,action) => {
      state.priceDetail=action.payload
    },
    getpartnerdetail: (state,action) => {
      state.partnerDetail=action.payload
    },
    geteditPackage: (state,action) => {
      state.editPackage = action.payload
    },
    geteditChildSeat: (state,action) => {
      state.editChildSeat = action.payload
    },
    geteditExtraOptions: (state,action) => {
      state.editExtraOptions = action.payload
    },
    getAssignDriverId: (state,action) => {
      state.assignDriverId = action.payload
    },
    getEditVehicalModel: (state,action) => {
      state.editVehicalModel = action.payload
    },
    getEditVehicalColor: (state,action) => {
      state.editVehicalColor = action.payload
    },
    // regBtn: (state, action) => {
    //   state.reg = true;
    // },
    // showModalLogin: (state, action) => {
    //   state.show = true;
    // },
    // showModalClose: (state, action) => {
    //   state.show = false;
    // },
    // isAdmin: (state, action) => {
    //   state.admin = action.payload.access_token;
    //   state.user = action.payload
    // },
    reset: (state, action) => {
      state.token = null;
      state.user = {};
      // state.admin = null;
      state.login = false;
      // state.reg = false;
      // state.show = false;
    },
  },
});

export const {
  authToken,
  auth,
  logoutUser,
  reset,
  getvehicledetail,
  gettaxdetail,
  getzonedetail,
  getpricedetail,
  getpartnerdetail,
  geteditChildSeat,
  geteditExtraOptions,
  geteditPackage,
  getAssignDriverId,
  getEditVehicalModel,
  getEditVehicalColor
} = login.actions;

export default login.reducer;
