import React, { lazy, Suspense, useEffect } from "react";
import { paths } from "../config/constants";
import { Route, Routes, useLocation } from "react-router-dom";
import Error from "../pages/Error";
import AppLayout from "../component/layout/AppLayout";
import { PageLoading } from "../component/includes/PageLoading";
import AdminLayout from "../component/layout/AdminLayout";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import VehicleModel from "../pages/admin/VehicleModel";
import AddVehicleModel from "../pages/admin/AddVehicleModel";
import VehicleColor from "../pages/admin/VehicleColor";
import AddVehicleColor from "../pages/admin/AddVehicleColor";

//Common
const Imprint = lazy(() => import("../pages/Imprint"));
const NewsAndUpdate = lazy(() => import("../pages/NewsAndUpdate"));
const AboutUs = lazy(() => import("../pages/AboutUs"));
const TermCondition = lazy(() => import("../pages/TermAndCondition"));

//admin
const Dashboard = lazy(() => import("../pages/admin/Dashboard"));
const PartnerList = lazy(() => import("../pages/admin/PartnerList"));
const PartnerSetUp = lazy(() => import("../pages/admin/PartnerSetup"));
const UserManager = lazy(() => import("../pages/admin/UserManager"));
const AddNewVehicle = lazy(() => import("../pages/admin/AddnewVehicle"));
const AdminBooking = lazy(() => import("../pages/admin/AdminBooking"));
const EditBooking = lazy(() => import("../pages/admin/EditBooking"));
const BookingIdPage = lazy(() => import("../pages/admin/BookingIdPage"));
const AllVehicle = lazy(() => import("../pages/admin/VehicleList"));
const Zone = lazy(() => import("../pages/admin/Zone"));
const Package = lazy(() => import("../pages/admin/Package"));
const ExtraOptions = lazy(() => import("../pages/admin/ExtraOptions"));
const ChildSeat = lazy(() => import("../pages/admin/ChildSeat"));
const EditPackage = lazy(() => import("../pages/admin/EditPackage"));
const EditChildSeat = lazy(() => import("../pages/admin/EditChildSeat"));
const EditExtraOptions = lazy(() => import("../pages/admin/EditExtraOptions"));
const Price = lazy(() => import("../pages/admin/Price"));
const Comession = lazy(() => import("../pages/admin/Commesion"));
const TaxValue = lazy(() => import("../pages/admin/TaxValue"));
const AddTax = lazy(() => import("../pages/admin/AddTax"));
const EditTax = lazy(() => import("../pages/admin/EditTaxValue"));
const EditVehicle = lazy(() => import("../pages/admin/EditVehicle"));
const AddZonePrice = lazy(() => import("../pages/admin/AddZonePrice"));
const AddZone = lazy(() => import("../pages/admin/AddZone"));
const EditZonePrice = lazy(() => import("../pages/admin/EditZonePrice"));
const AddExcelZonePrice = lazy(() => import("../pages/admin/AddExcelZonePrice"));
const EditZone = lazy(() => import("../pages/admin/EditZone"));
const Settings = lazy(() => import("../pages/admin/Settings"));
const Coupon = lazy(() => import("../pages/admin/Coupon"));
const DriverAssig = lazy(() => import("../pages/admin/DriverAssig"));
const PaymentDetail = lazy(() => import("../pages/admin/PaymentDetail"))

const CommonPrice = lazy(() => import("../pages/admin/CommonPrice"));
const AirportPrice = lazy(() => import("../pages/admin/AirportPrice"));
const NewPriceRules = lazy(() => import("../pages/admin/NewPriceRules"));
const VehicleCatSec = lazy(() => import("../pages/admin/VehicleCategorySec"));
const AddCommonPrice = lazy(() => import("../pages/admin/AddCommonPrice"));
const AddAirportPrice = lazy(() => import("../pages/admin/AddAirportPrice"));
const AddNewPriceRule = lazy(() => import("../pages/admin/AddNewPriceRule"));
const AddExcelPriceRule = lazy(() => import("../pages/admin/AddExcelPriceRule"));
const AddExcelCommonPrice = lazy(() => import("../pages/admin/AddExcelCommonPrice"));
const AddExcelAirportPrice = lazy(() => import("../pages/admin/AddExcelAirportPrice"));
const AddVehicleCategory = lazy(() => import("../pages/admin/AddVehicleCtegory"));
//auth
const ForgetPassword = lazy(() => import("../pages/auth/ForgetPassword"));
const LogIn = lazy(() => import("../pages/auth/Login"));

function RouteApp() {
  const { pathname } = useLocation();
  const logingUser = useSelector((state) => state);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: "de",
        autoDisplay: true,
        defaultLanguage: 'de',
        // includedLanguages: "en,de,fr"
      },
      "google_translate_element"
    );
  };

  useEffect(() => {
    var addScript = document.createElement("script");
    addScript.setAttribute(
      "src",
      "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
    );
    document.body.appendChild(addScript);
    window.googleTranslateElementInit = googleTranslateElementInit;
    const poweredByGoogle = document.querySelector('.goog-logo-link');
    if (poweredByGoogle) {
      poweredByGoogle.style.display = 'none';
    }
  }, []);



  return (
    <>
      {/* <div id="google_translate_element" style={{position:"absolute", right:"0"}}></div> */}
      <div></div>
      <Routes>
        <Route
          index
          path={paths.home}
          element={
            <Suspense fallback={<PageLoading />}>
              <LogIn />
            </Suspense>
          }
        />
        {logingUser.login.token && (
          <Route path={paths.admin.home} element={<AdminLayout />}>
            <Route
              path={paths.admin.dashboard}
              element={
                <Suspense fallback={<PageLoading />}>
                  <Dashboard />
                </Suspense>
              }
            />
            <Route
              path={paths.admin.partnerList}
              element={
                <Suspense fallback={<PageLoading />}>
                  <PartnerList />
                </Suspense>
              }
            />
            <Route
              path={paths.admin.partnerSetup}
              element={
                <Suspense fallback={<PageLoading />}>
                  <PartnerSetUp />
                </Suspense>
              }
            />
            <Route
              path={paths.admin.userManager}
              element={
                <Suspense fallback={<PageLoading />}>
                  <UserManager />
                </Suspense>
              }
            />
            <Route
              path={paths.admin.addnewVehicle}
              element={
                <Suspense fallback={<PageLoading />}>
                  <AddNewVehicle />
                </Suspense>
              }
            />
            <Route
              path={paths.admin.paymentdetail}
              element={
                <Suspense fallback={<PageLoading />}>
                  <PaymentDetail />
                </Suspense>
              }
            />
            <Route
              path={paths.admin.assignDriver}
              element={
                <Suspense fallback={<PageLoading />}>
                  <DriverAssig />
                </Suspense>
              }
            />
            <Route
              path={paths.admin.adminbookingId}
              element={
                <Suspense fallback={<PageLoading />}>
                  <BookingIdPage />
                </Suspense>
              }
            />
            <Route
              path={paths.admin.allVehicle}
              element={
                <Suspense fallback={<PageLoading />}>
                  <AllVehicle />
                </Suspense>
              }
            />
            <Route
              path={paths.admin.priceRule}
              element={
                <Suspense fallback={<PageLoading />}>
                  <Price />
                </Suspense>
              }
            />
            <Route
              path={paths.admin.vehicleCategories}
              element={
                <Suspense fallback={<PageLoading />}>
                  <VehicleModel />
                </Suspense>
              }
            />
            <Route
              path={paths.admin.addVehicleCategories}
              element={
                <Suspense fallback={<PageLoading />}>
                  <AddVehicleModel />
                </Suspense>
              }
            />
            <Route
              path={"/admin/addVehicleCategories/:id"}
              element={
                <Suspense fallback={<PageLoading />}>
                  <AddVehicleModel />
                </Suspense>
              }
            />


            <Route
              path={paths.admin.vehicleColor}
              element={
                <Suspense fallback={<PageLoading />}>
                  <VehicleColor />
                </Suspense>
              }
            />
            <Route
              path={paths.admin.addVehicleColor}
              element={
                <Suspense fallback={<PageLoading />}>
                  <AddVehicleColor />
                </Suspense>
              }
            />
            <Route
              path={"/admin/addVehicleColour/:id"}
              element={
                <Suspense fallback={<PageLoading />}>
                  <AddVehicleColor />
                </Suspense>
              }
            />


            <Route
              path={paths.admin.commonPrice}
              element={
                <Suspense fallback={<PageLoading />}>
                  <CommonPrice />
                </Suspense>
              }
            />

            <Route
              path={paths.admin.addCommonPrice}
              element={
                <Suspense fallback={<PageLoading />}>
                  <AddCommonPrice />
                </Suspense>
              }
            />

            <Route
              path={"/admin/add-common-price/:id"}
              element={
                <Suspense fallback={<PageLoading />}>
                  <AddCommonPrice />
                </Suspense>
              }
            />
            <Route
              path={paths.admin.airportPrice}
              element={
                <Suspense fallback={<PageLoading />}>
                  <AirportPrice />
                </Suspense>
              }
            />

            <Route
              path={paths.admin.addAirportPrice}
              element={
                <Suspense fallback={<PageLoading />}>
                  <AddAirportPrice />
                </Suspense>
              }
            />

            <Route
              path={"/admin/add-airport-price/:id"}
              element={
                <Suspense fallback={<PageLoading />}>
                  <AddAirportPrice />
                </Suspense>
              }
            />

            <Route
              path={paths.admin.newPriceRule}
              element={
                <Suspense fallback={<PageLoading />}>
                  <NewPriceRules />
                </Suspense>
              }
            />
            <Route
              path={paths.admin.addNewPriceRule}
              element={
                <Suspense fallback={<PageLoading />}>
                  <AddNewPriceRule />
                </Suspense>
              }
            />
            <Route
              path={paths.admin.addExcelPriceRule}
              element={
                <Suspense fallback={<PageLoading />}>
                  <AddExcelPriceRule />
                </Suspense>
              }
            />
            <Route
              path={paths.admin.addExcelCommonPrice}
              element={
                <Suspense fallback={<PageLoading />}>
                  <AddExcelCommonPrice />
                </Suspense>
              }
            />
            <Route
              path={paths.admin.addExcelAirportPrice}
              element={
                <Suspense fallback={<PageLoading />}>
                  <AddExcelAirportPrice />
                </Suspense>
              }
            />

            <Route
              path={"/admin/add-new-price-rule/:id"}
              element={
                <Suspense fallback={<PageLoading />}>
                  <AddNewPriceRule />
                </Suspense>
              }
            />

            <Route
              path={paths.admin.addVehicleCategory}
              element={
                <Suspense fallback={<PageLoading />}>
                  <AddVehicleCategory />
                </Suspense>
              }
            />
            <Route
              path={paths.admin.vehicleCategorySec}
              element={
                <Suspense fallback={<PageLoading />}>
                  <VehicleCatSec />
                </Suspense>
              }
            />

            <Route
              path={"/admin/add-vehicle-category/:id"}
              element={
                <Suspense fallback={<PageLoading />}>
                  <AddVehicleCategory />
                </Suspense>
              }
            />


            <Route
              path={paths.admin.editTax}
              element={
                <Suspense fallback={<PageLoading />}>
                  <EditTax />
                </Suspense>
              }
            />
            <Route
              path={paths.admin.zone}
              element={
                <Suspense fallback={<PageLoading />}>
                  <Zone />
                </Suspense>
              }
            />
            <Route
              path={paths.admin.package}
              element={
                <Suspense fallback={<PageLoading />}>
                  <Package />
                </Suspense>
              }
            />
            <Route
              path={paths.admin.childSeat}
              element={
                <Suspense fallback={<PageLoading />}>
                  <ChildSeat />
                </Suspense>
              }
            />
            <Route
              path={paths.admin.extraOptions}
              element={
                <Suspense fallback={<PageLoading />}>
                  <ExtraOptions />
                </Suspense>
              }
            />
            <Route
              path={paths.admin.editpackage}
              element={
                <Suspense fallback={<PageLoading />}>
                  <EditPackage />
                </Suspense>
              }
            />
            <Route
              path={paths.admin.editchildSeat}
              element={
                <Suspense fallback={<PageLoading />}>
                  <EditChildSeat />
                </Suspense>
              }
            />
            <Route
              path={paths.admin.editextraOptions}
              element={
                <Suspense fallback={<PageLoading />}>
                  <EditExtraOptions />
                </Suspense>
              }
            />
            <Route
              path={paths.admin.settings}
              element={
                <Suspense fallback={<PageLoading />}>
                  <Settings />
                </Suspense>
              }
            />
            <Route
              path={paths.admin.coupon}
              element={
                <Suspense fallback={<PageLoading />}>
                  <Coupon />
                </Suspense>
              }
            />
            <Route
              path={paths.admin.addzoneprice}
              element={
                <Suspense fallback={<PageLoading />}>
                  <AddZonePrice />
                </Suspense>
              }
            />
            <Route
              path={paths.admin.editzoneprice}
              element={
                <Suspense fallback={<PageLoading />}>
                  <EditZonePrice />
                </Suspense>
              }
            />
            <Route
              path={"/admin/editzoneprice/:id"}
              element={
                <Suspense fallback={<PageLoading />}>
                  <EditZonePrice />
                </Suspense>
              }
            />
            <Route
              path={"/admin/addExcelZoneprice"}
              element={
                <Suspense fallback={<PageLoading />}>
                  <AddExcelZonePrice />
                </Suspense>
              }
            />
            <Route
              path={paths.admin.addzone}
              element={
                <Suspense fallback={<PageLoading />}>
                  <AddZone />
                </Suspense>
              }
            />
            <Route
              path={paths.admin.editzone}
              element={
                <Suspense fallback={<PageLoading />}>
                  <EditZone />
                </Suspense>
              }
            />


            <Route
              path={paths.admin.comssion}
              element={
                <Suspense fallback={<PageLoading />}>
                  <Comession />
                </Suspense>
              }
            />
            <Route
              path={paths.admin.taxValue}
              element={
                <Suspense fallback={<PageLoading />}>
                  <TaxValue />
                </Suspense>
              }
            />

            <Route
              path={paths.admin.addTax}
              element={
                <Suspense fallback={<PageLoading />}>
                  <AddTax />
                </Suspense>
              }
            />
            <Route
              path={paths.admin.editVehicle}
              element={
                <Suspense fallback={<PageLoading />}>
                  <EditVehicle />
                </Suspense>
              }
            />
            <Route
              path={paths.admin.adminbooking}
              element={
                <Suspense fallback={<PageLoading />}>
                  <AdminBooking />
                </Suspense>
              }
            />
            <Route
              path={"/admin/editBooking/:id"}
              element={
                <Suspense fallback={<PageLoading />}>
                  <EditBooking />
                </Suspense>
              }
            />
          </Route>
        )}

        {/* common pages */}
        <Route path={paths.home} element={<AppLayout />}>
          {/* <Route
            path={paths.admin.adminbooking}
            element={
              <Suspense fallback={<PageLoading />}>
                <AdminBooking />
              </Suspense>
            }
          /> */}
          <Route
            path={paths.termCondition}
            element={
              <Suspense fallback={<PageLoading />}>
                <TermCondition />
              </Suspense>
            }
          />
          <Route
            path={paths.aboutUs}
            element={
              <Suspense fallback={<PageLoading />}>
                <AboutUs />
              </Suspense>
            }
          />
          <Route
            path={paths.newsAndUpdate}
            element={
              <Suspense fallback={<PageLoading />}>
                <NewsAndUpdate />
              </Suspense>
            }
          />
          <Route
            path={paths.imprint}
            element={
              <Suspense fallback={<PageLoading />}>
                <Imprint />
              </Suspense>
            }
          />
          <Route
            path={paths.forgetPassword}
            element={
              <Suspense fallback={<PageLoading />}>
                <ForgetPassword />
              </Suspense>
            }
          />

        </Route>

        <Route
          path={"*"}
          element={
            <Suspense fallback={<PageLoading />}>
              <Error />
            </Suspense>
          }
        />

      </Routes>

      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
}

export default RouteApp;
