import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import RouteInit from './routes/RouteInit';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from "redux-persist";
import { store } from './redux/Store';
const persistor = persistStore(store);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      {/* <React.StrictMode> */}
        <BrowserRouter>
          <RouteInit />
        </BrowserRouter>
      {/* </React.StrictMode> */}
    </PersistGate>
  </Provider>
);

reportWebVitals();
