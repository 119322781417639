const base = '/';

// let apiBase = "https://i5y2sl7e13.execute-api.ap-south-1.amazonaws.com/api/";

export const paths = {
    home: base,
 
    admin: {
        home: base + 'admin',
        dashboard: base + 'admin/dashboard',
        partnerList: base + 'admin/partnerlist',
        partnerSetup: base + 'admin/partnersetup/:id',
        userManager: base + 'admin/usermanager',
        addnewVehicle: base + 'admin/addnewvehicle',
        adminbooking: base + 'admin/adminbooking',
        adminbookingId: base + 'admin/adminbooking-id',
        allVehicle: base + 'admin/all-vehicle',
        zone: base + 'admin/zone',
        childSeat: base + 'admin/childseat',
        editchildSeat: base + 'admin/editchildseat',
        package: base + 'admin/package',
        editpackage: base + 'admin/editpackage',
        extraOptions: base + 'admin/extraoptions',
        editextraOptions: base + 'admin/editextraoptions',
        priceRule: base + 'admin/price-rule',
        taxValue: base + 'admin/tax-value',
        comssion: base + 'admin/comssion',
        addTax: base + 'admin/add-tax',
        editTax: base + 'admin/edit-tax',
        editVehicle: base + 'admin/all-vehicle/edit-vehicle',
        addzoneprice: base + 'admin/addzoneprice',
        editzoneprice: base + 'admin/editzoneprice',
        paymentdetail: base + 'admin/paymentdetail',
        addzone: base + 'admin/addzone',
        editzone: base + 'admin/editzone',
        settings: base + 'admin/settings',
        coupon: base + 'admin/coupon',
        newPriceRule: base + 'admin/new-price-rules',
        vehicleCategories: base + 'admin/VehicleCategories',
        addVehicleCategories: base + 'admin/addVehicleCategories',
        vehicleCategorySec: base + 'admin/vehicle-category-section',
        addNewPriceRule: base + 'admin/add-new-price-rule',
        addExcelPriceRule: base + 'admin/add-excel-price-rule',
        commonPrice: base + 'admin/common-price',
        addExcelCommonPrice: base + 'admin/add-excel-common-price',
        addCommonPrice: base + 'admin/add-common-price',
        airportPrice: base + 'admin/airport-price',
        addExcelAirportPrice: base + 'admin/add-excel-airport-price',
        addAirportPrice: base + 'admin/add-airport-price',
        assignDriver: base + 'admin/assign-driver',
        // addCommonPrice/:id: base + 'admin/add-common-price/:id',
        addVehicleCategory: base + 'admin/add-vehicle-category',
        vehicleColor: base + 'admin/vehicleColour',
        addVehicleColor: base + 'admin/addVehicleColour',
    },


   
    adminbooking: base + 'adminbooking', 
    termCondition: base + 'term-Condition',
    aboutUs: base + 'about-us',
    newsAndUpdate: base + 'news-and-update',
    imprint: base + 'imprint',
    // login: base + 'login',
    forgetPassword: base + 'forget-password',
}